/*eslint-disable*/ export const messages = {
    '25': '25',
    '50': '50',
    '100': '100',
    '250': '250',
    '500': '500',
    "'expanded closed": "'expanded closed",
    "'expanded open": "'expanded open",
    '(all versions)': '(all versions)',
    '0 results found when searching for "{search}"': ['0 results found when searching for "', ['search'], '"'],
    '<0/> — <1>{title}</1>': ['<0/> — <1>', ['title'], '</1>'],
    '<0/>Prev': '<0/>Prev',
    '<0>-- for sale</0> from --': '<0>-- for sale</0> from --',
    '<0><1/> Add Release</0>': '<0><1/> Add Release</0>',
    '<0>Add Release</0>': '<0>Add Release</0>',
    '<0>Click here</0> if you would like to view it in {from} instead of your default language ({to}).': [
        '<0>Click here</0> if you would like to view it in ',
        ['from'],
        ' instead of your default language (',
        ['to'],
        ').',
    ],
    '<0>Create Master Release</0>': '<0>Create Master Release</0>',
    '<0>Credits</0>': '<0>Credits</0>',
    '<0>DO NOT USE</0> Please use <1>{0}</1> instead.': ['<0>DO NOT USE</0> Please use <1>', ['0'], '</1> instead.'],
    '<0>Learn more about selling on Discogs<1/></0>': '<0>Learn more about selling on Discogs<1/></0>',
    '<0>Showing 0 - 0 of 0</0>': '<0>Showing 0 - 0 of 0</0>',
    '<0>{0} for sale</0> from <1/>': ['<0>', ['0'], ' for sale</0> from <1/>'],
    '<0>{0}</0>': ['<0>', ['0'], '</0>'],
    '<0>{creditName}</0>': ['<0>', ['creditName'], '</0>'],
    '<0>{name}</0> by <1>{username}</1>': ['<0>', ['name'], '</0> by <1>', ['username'], '</1>'],
    '<0>{submissionsPending} submissions pending</0>': ['<0>', ['submissionsPending'], ' submissions pending</0>'],
    API: 'API',
    ASIN: 'ASIN',
    'About Discogs': 'About Discogs',
    'Accessibility Statement': 'Accessibility Statement',
    Actions: 'Actions',
    Ad: 'Ad',
    Add: 'Add',
    'Add An Image': 'Add An Image',
    'Add Artist to List': 'Add Artist to List',
    'Add Field': 'Add Field',
    'Add Folder': 'Add Folder',
    'Add List To Dashboard': 'Add List To Dashboard',
    'Add Master to List': 'Add Master to List',
    'Add Release': 'Add Release',
    'Add Release to List': 'Add Release to List',
    'Add Review': 'Add Review',
    'Add Selected Release to Collection': 'Add Selected Release to Collection',
    'Add Selected Release to Inventory': 'Add Selected Release to Inventory',
    'Add Selected Release to List': 'Add Selected Release to List',
    'Add Selected Release to Wantlist': 'Add Selected Release to Wantlist',
    'Add To Collection': 'Add To Collection',
    'Add To Inventory': 'Add To Inventory',
    'Add To Wantlist': 'Add To Wantlist',
    'Add all versions to Wantlist': 'Add all versions to Wantlist',
    'Add description...': 'Add description...',
    'Add friend': 'Add friend',
    'Add notes...': 'Add notes...',
    'Add to Collection': 'Add to Collection',
    'Add to List': 'Add to List',
    'Add to Wantlist': 'Add to Wantlist',
    'Add to wantlist': 'Add to wantlist',
    'Add video': 'Add video',
    'Add {0} results to Wantlist': ['Add ', ['0'], ' results to Wantlist'],
    Added: 'Added',
    'Added folder': 'Added folder',
    'Added just now': 'Added just now',
    'Added video': 'Added video',
    'Advanced Search': 'Advanced Search',
    'Advertise With Us': 'Advertise With Us',
    Advertisement: 'Advertisement',
    Aliases: 'Aliases',
    All: 'All',
    'All Items': 'All Items',
    'All Lists': 'All Lists',
    'All Media': 'All Media',
    'All folders ({0})': ['All folders (', ['0'], ')'],
    'An error occurred when loading the videos.': 'An error occurred when loading the videos.',
    Applications: 'Applications',
    'Applying filters': 'Applying filters',
    'Are you sure you want to delete this field: "{0}"?': [
        'Are you sure you want to delete this field: "',
        ['0'],
        '"?',
    ],
    'Are you sure you want to delete this folder: "{0}"?': [
        'Are you sure you want to delete this folder: "',
        ['0'],
        '"?',
    ],
    'Are you sure you want to remove these items?': 'Are you sure you want to remove these items?',
    'Are you sure you want to remove this item?': 'Are you sure you want to remove this item?',
    Artist: 'Artist',
    'Artist (A-Z)': 'Artist (A-Z)',
    'Artist (Z-A)': 'Artist (Z-A)',
    Artists: 'Artists',
    'At least 10 words must be entered. Please enter at least {missingWords} more.': [
        'At least 10 words must be entered. Please enter at least ',
        ['missingWords'],
        ' more.',
    ],
    Audio: 'Audio',
    'Avg Rating': 'Avg Rating',
    'Bad gateway.': 'Bad gateway.',
    'Bad request.': 'Bad request.',
    Barcode: 'Barcode',
    'Barcode and Other Identifiers': 'Barcode and Other Identifiers',
    'Based on last 30 sales': 'Based on last 30 sales',
    'Betacam-SP': 'Betacam-SP',
    Billing: 'Billing',
    'Blu-ray': 'Blu-ray',
    'Bottom pagination': 'Bottom pagination',
    'Box Set': 'Box Set',
    'Buy Music': 'Buy Music',
    Buyer: 'Buyer',
    By: 'By',
    CD: 'CD',
    CDr: 'CDr',
    'California Privacy Notice': 'California Privacy Notice',
    Cancel: 'Cancel',
    Careers: 'Careers',
    Cart: 'Cart',
    Cassette: 'Cassette',
    'Cat#': 'Cat#',
    'Catalog Number': 'Catalog Number',
    'Catalog Number (0-9)': 'Catalog Number (0-9)',
    'Catalog Number (9-0)': 'Catalog Number (9-0)',
    Categories: 'Categories',
    Choices: 'Choices',
    'Clear All': 'Clear All',
    'Clear Selection': 'Clear Selection',
    'Clear all': 'Clear all',
    Close: 'Close',
    'Close Versions': 'Close Versions',
    'Collapse row': 'Collapse row',
    'Collapse section': 'Collapse section',
    Collected: 'Collected',
    Collection: 'Collection',
    'Collection Items': 'Collection Items',
    'Collection Settings': 'Collection Settings',
    'Collection bottom pagination': 'Collection bottom pagination',
    'Comments on this item': 'Comments on this item',
    Community: 'Community',
    'Community Guidelines': 'Community Guidelines',
    'Companies, etc.': 'Companies, etc.',
    'Complete and correct': 'Complete and correct',
    'Conflict.': 'Conflict.',
    'Contact Info': 'Contact Info',
    Contribute: 'Contribute',
    Contributed: 'Contributed',
    'Contributor List': 'Contributor List',
    Contributors: 'Contributors',
    'Cookie Policy': 'Cookie Policy',
    'Copy {0} Code': ['Copy ', ['0'], ' Code'],
    'Copyright ©': 'Copyright ©',
    Correct: 'Correct',
    'Could not add Collection note field': 'Could not add Collection note field',
    'Could not add folder': 'Could not add folder',
    'Could not add release to collection': 'Could not add release to collection',
    'Could not add release to list': 'Could not add release to list',
    'Could not add release to wantlist': 'Could not add release to wantlist',
    'Could not change your language preference': 'Could not change your language preference',
    'Could not delete folder': 'Could not delete folder',
    'Could not edit release videos': 'Could not edit release videos',
    'Could not edit the collection note': 'Could not edit the collection note',
    'Could not edit the list': 'Could not edit the list',
    'Could not edit the list item': 'Could not edit the list item',
    'Could not edit videos': 'Could not edit videos',
    'Could not edit wantlist note': 'Could not edit wantlist note',
    'Could not mark review as helpful': 'Could not mark review as helpful',
    'Could not move collection item': 'Could not move collection item',
    'Could not remove Collection note field': 'Could not remove Collection note field',
    'Could not remove collection note': 'Could not remove collection note',
    'Could not remove list': 'Could not remove list',
    'Could not remove release from wantlist': 'Could not remove release from wantlist',
    'Could not remove review': 'Could not remove review',
    'Could not remove the list item': 'Could not remove the list item',
    'Could not remove the release from your collection': 'Could not remove the release from your collection',
    'Could not rename folder': 'Could not rename folder',
    'Could not save changes to Collection note field': 'Could not save changes to Collection note field',
    'Could not update dashboard': 'Could not update dashboard',
    Count: 'Count',
    Countries: 'Countries',
    Country: 'Country',
    'Cover of {0}, {1}, {2}': ['Cover of ', ['0'], ', ', ['1'], ', ', ['2']],
    'Cover of {title}': ['Cover of ', ['title']],
    Created: 'Created',
    Credits: 'Credits',
    'Credits ({0})': ['Credits (', ['0'], ')'],
    'Current Videos': 'Current Videos',
    DVD: 'DVD',
    Dashboard: 'Dashboard',
    'Data Correct': 'Data Correct',
    'Data Quality': 'Data Quality',
    'Data quality rating:': 'Data quality rating:',
    'Database Guidelines': 'Database Guidelines',
    'Date added': 'Date added',
    Delete: 'Delete',
    'Delete Field': 'Delete Field',
    'Delete List': 'Delete List',
    'Delete Review': 'Delete Review',
    'Delete This List': 'Delete This List',
    'Delete forever': 'Delete forever',
    'Depósito Legal': 'Depósito Legal',
    Description: 'Description',
    'Designed At': 'Designed At',
    Developers: 'Developers',
    'Did you mean the {0} named {1}': ['Did you mean the ', ['0'], ' named ', ['1']],
    'Digital content is not available for sale or trade.': 'Digital content is not available for sale or trade.',
    Disco: 'Disco',
    'Discogs Shipping': 'Discogs Shipping',
    'Discogs is not responsible for the accuracy, legality, or content of external sites or for that of subsequent links.':
        'Discogs is not responsible for the accuracy, legality, or content of external sites or for that of subsequent links.',
    Discover: 'Discover',
    'Distributed By': 'Distributed By',
    'Distribution Code': 'Distribution Code',
    'Do you really want to remove this list?': 'Do you really want to remove this list?',
    'Do you really want to remove this review?': 'Do you really want to remove this review?',
    'Download now from Google Play': 'Download now from Google Play',
    'Download now from the App Store': 'Download now from the App Store',
    Draft: 'Draft',
    Drafts: 'Drafts',
    Dropdown: 'Dropdown',
    'Duplicated By': 'Duplicated By',
    Edit: 'Edit',
    'Edit Artist': 'Edit Artist',
    'Edit Images': 'Edit Images',
    'Edit Label': 'Edit Label',
    'Edit Master Release': 'Edit Master Release',
    'Edit Notes': 'Edit Notes',
    'Edit Release': 'Edit Release',
    'Edit collection note': 'Edit collection note',
    'Edited At': 'Edited At',
    'Edited just now': 'Edited just now',
    'Editing Videos for <0>{name}</0>': ['Editing Videos for <0>', ['name'], '</0>'],
    Electronic: 'Electronic',
    'Enable Keyboard Shortcuts:': 'Enable Keyboard Shortcuts:',
    'Engineered At': 'Engineered At',
    English: 'English',
    'Enter your comment': 'Enter your comment',
    'Enter your reply': 'Enter your reply',
    'Entirely Incorrect': 'Entirely Incorrect',
    Error: 'Error',
    'Error fetching random item': 'Error fetching random item',
    'Estimated Collection Value': 'Estimated Collection Value',
    Everything: 'Everything',
    'Exclusive Retailer': 'Exclusive Retailer',
    'Existing List': 'Existing List',
    'Expand row': 'Expand row',
    'Expand section': 'Expand section',
    'Expectation failed.': 'Expectation failed.',
    Experimental: 'Experimental',
    Explore: 'Explore',
    'Explore All': 'Explore All',
    'Explore lists from the Discogs community. Lists can be about anything–notable album covers, prolific producers, your favorite holiday albums. The possibilities are endless! Lists can contain artists, releases, labels, or even other lists.':
        'Explore lists from the Discogs community. Lists can be about anything–notable album covers, prolific producers, your favorite holiday albums. The possibilities are endless! Lists can contain artists, releases, labels, or even other lists.',
    "Explore music from the {name} label. Discover what's missing in your collection and shop for {name} releases.": [
        'Explore music from the ',
        ['name'],
        " label. Discover what's missing in your collection and shop for ",
        ['name'],
        ' releases.',
    ],
    'Explore music from {name}. Shop for vinyl, CDs, and more from {name} on Discogs.': [
        'Explore music from ',
        ['name'],
        '. Shop for vinyl, CDs, and more from ',
        ['name'],
        ' on Discogs.',
    ],
    'Explore songs, recommendations, and other album details for {title} by {0}. Compare different versions and buy them all on Discogs.':
        [
            'Explore songs, recommendations, and other album details for ',
            ['title'],
            ' by ',
            ['0'],
            '. Compare different versions and buy them all on Discogs.',
        ],
    'Explore the discography of {name}. Shop for vinyl, CDs, and more from {name} on Discogs.': [
        'Explore the discography of ',
        ['name'],
        '. Shop for vinyl, CDs, and more from ',
        ['name'],
        ' on Discogs.',
    ],
    'Explore the tracklist, credits, statistics, and more for {title} by {0}. Compare versions and buy on Discogs': [
        'Explore the tracklist, credits, statistics, and more for ',
        ['title'],
        ' by ',
        ['0'],
        '. Compare versions and buy on Discogs',
    ],
    Export: 'Export',
    'Export Collection': 'Export Collection',
    'Exported By': 'Exported By',
    Facebook: 'Facebook',
    'Failed dependency.': 'Failed dependency.',
    'Field Name': 'Field Name',
    File: 'File',
    'Filmed At': 'Filmed At',
    'Filter by': 'Filter by',
    Filters: 'Filters',
    'Find a country': 'Find a country',
    'Find a format': 'Find a format',
    'Find a label or company': 'Find a label or company',
    'Find a year': 'Find a year',
    'Focus on Search': 'Focus on Search',
    Folder: 'Folder',
    'Folder Name': 'Folder Name',
    'Folder deleted': 'Folder deleted',
    'Folder renamed': 'Folder renamed',
    'Folk, World, & Country': 'Folk, World, & Country',
    'Follow Us': 'Follow Us',
    Footer: 'Footer',
    'For Sale': 'For Sale',
    'For sale on Discogs': 'For sale on Discogs',
    'Forbidden.': 'Forbidden.',
    Format: 'Format',
    'Format (A-Z)': 'Format (A-Z)',
    'Format (Z-A)': 'Format (Z-A)',
    Formats: 'Formats',
    Forum: 'Forum',
    French: 'French',
    Friends: 'Friends',
    From: 'From',
    'Funk / Soul': 'Funk / Soul',
    'Gateway timeout.': 'Gateway timeout.',
    Genre: 'Genre',
    Genres: 'Genres',
    German: 'German',
    'Get Started': 'Get Started',
    'Glass Mastered At': 'Glass Mastered At',
    'Go To Collection': 'Go To Collection',
    'Go To Dashboard': 'Go To Dashboard',
    'Go To Edit': 'Go To Edit',
    'Go To Forum': 'Go To Forum',
    'Go To Groups': 'Go To Groups',
    'Go To Inventory': 'Go To Inventory',
    'Go To Messages': 'Go To Messages',
    'Go To Orders': 'Go To Orders',
    'Go To Posted': 'Go To Posted',
    'Go To Purchases': 'Go To Purchases',
    'Go To Recent': 'Go To Recent',
    'Go To Saved': 'Go To Saved',
    'Go To Started': 'Go To Started',
    'Go To Submissions': 'Go To Submissions',
    'Go To The Selected Release': 'Go To The Selected Release',
    'Go To User Profile': 'Go To User Profile',
    'Go To Wantlist': 'Go To Wantlist',
    'Go To Watched': 'Go To Watched',
    'Go to Discogs.com homepage': 'Go to Discogs.com homepage',
    'Gone.': 'Gone.',
    Groups: 'Groups',
    'HTTP version not supported.': 'HTTP version not supported.',
    Have: 'Have',
    'Have a unique version? <0>Add a Release</0>': 'Have a unique version? <0>Add a Release</0>',
    Help: 'Help',
    'Help & Support': 'Help & Support',
    'Help Is Here': 'Help Is Here',
    'Help Translate': 'Help Translate',
    'Help Translate Discogs': 'Help Translate Discogs',
    'Help!': 'Help!',
    Helpful: 'Helpful',
    'Here are a few lists we think deserve a mention.': 'Here are a few lists we think deserve a mention.',
    'Hide Ad': 'Hide Ad',
    'Hide Credits': 'Hide Credits',
    'Hide replies': 'Hide replies',
    High: 'High',
    'High Price (0-9)': 'High Price (0-9)',
    'High Price (9-0)': 'High Price (9-0)',
    Highest: 'Highest',
    'Hip Hop': 'Hip Hop',
    House: 'House',
    'Hover on an item\'s comments and click to edit, or click "remove" to remove.':
        'Hover on an item\'s comments and click to edit, or click "remove" to remove.',
    'Hover on the title or description then click to edit.': 'Hover on the title or description then click to edit.',
    'How to Make a List': 'How to Make a List',
    "I'm a teapot.": "I'm a teapot.",
    ISRC: 'ISRC',
    Image: 'Image',
    'Image Gallery': 'Image Gallery',
    'Image hidden': 'Image hidden',
    'Image hidden due to content': 'Image hidden due to content',
    Images: 'Images',
    'In Collection': 'In Collection',
    'In Groups': 'In Groups',
    'In Wantlist': 'In Wantlist',
    'In Your Collection, Wantlist, or Inventory': 'In Your Collection, Wantlist, or Inventory',
    'Insufficient space on resource.': 'Insufficient space on resource.',
    'Insufficient storage.': 'Insufficient storage.',
    Inventory: 'Inventory',
    Italian: 'Italian',
    'Items I Want': 'Items I Want',
    Japanese: 'Japanese',
    Jazz: 'Jazz',
    'Join In': 'Join In',
    'Keyboard Shortcuts': 'Keyboard Shortcuts',
    Korean: 'Korean',
    Label: 'Label',
    'Label (A-Z)': 'Label (A-Z)',
    'Label (Z-A)': 'Label (Z-A)',
    'Label Code': 'Label Code',
    'Label releases bottom pagination': 'Label releases bottom pagination',
    'Label releases top pagination': 'Label releases top pagination',
    Labels: 'Labels',
    'Labels & Companies': 'Labels & Companies',
    'Labels or Companies': 'Labels or Companies',
    Labs: 'Labs',
    'Lacquer Cut At': 'Lacquer Cut At',
    'Last Sold': 'Last Sold',
    'Learn more about blocked items on Discogs': 'Learn more about blocked items on Discogs',
    'Learn more about selling on Discogs': 'Learn more about selling on Discogs',
    'Learn why': 'Learn why',
    Legal: 'Legal',
    'Length required.': 'Length required.',
    'Licensed From': 'Licensed From',
    'Licensed Through': 'Licensed Through',
    'Licensed To': 'Licensed To',
    Lines: 'Lines',
    Links: 'Links',
    List: 'List',
    'List Explorer': 'List Explorer',
    'List Items For Sale': 'List Items For Sale',
    Lists: 'Lists',
    'Lists We Like': 'Lists We Like',
    'Lists by {username}': ['Lists by ', ['username']],
    'Lists by {username} | Discogs': ['Lists by ', ['username'], ' | Discogs'],
    'Load More Reviews': 'Load More Reviews',
    'Loading releases': 'Loading releases',
    'Loading versions': 'Loading versions',
    'Locked.': 'Locked.',
    'Log In': 'Log In',
    'Log Out': 'Log Out',
    'Log in to view image': 'Log in to view image',
    'Logged in as {username}': ['Logged in as ', ['username']],
    Low: 'Low',
    'Low Price (0-9)': 'Low Price (0-9)',
    'Low Price (9-0)': 'Low Price (9-0)',
    Lowest: 'Lowest',
    'Made By': 'Made By',
    Main: 'Main',
    'Main Menu': 'Main Menu',
    'Manage Collection Fields': 'Manage Collection Fields',
    'Manage Collection Folders': 'Manage Collection Folders',
    'Manage Collection Folders And Custom Fields': 'Manage Collection Folders And Custom Fields',
    'Manage Custom Fields': 'Manage Custom Fields',
    'Manage Custom Folders': 'Manage Custom Folders',
    'Manage List': 'Manage List',
    'Manage My Lists': 'Manage My Lists',
    'Manage Preferences': 'Manage Preferences',
    'Manage videos': 'Manage videos',
    'Manufactured By': 'Manufactured By',
    'Manufactured For': 'Manufactured For',
    'Manufacturer Contact': 'Manufacturer Contact',
    'Manufacturer EU Contact': 'Manufacturer EU Contact',
    'Marketed By': 'Marketed By',
    Marketplace: 'Marketplace',
    'Master Release': 'Master Release',
    'Mastered At': 'Mastered At',
    'Mastering SID Code': 'Mastering SID Code',
    'Matrix / Runout': 'Matrix / Runout',
    Maximum: 'Maximum',
    Med: 'Med',
    Media: 'Media',
    'Media condition': 'Media condition',
    Median: 'Median',
    'Median Price (0-9)': 'Median Price (0-9)',
    'Median Price (9-0)': 'Median Price (9-0)',
    Members: 'Members',
    'Method failure.': 'Method failure.',
    'Method not allowed.': 'Method not allowed.',
    Minimum: 'Minimum',
    'Misdirected request.': 'Misdirected request.',
    'Mixed At': 'Mixed At',
    'Monthly Leaderboards': 'Monthly Leaderboards',
    'More images': 'More images',
    'More results...': 'More results...',
    'Mould SID Code': 'Mould SID Code',
    'Move Selection Down': 'Move Selection Down',
    'Move Selection Up': 'Move Selection Up',
    'Move item to:': 'Move item to:',
    'Move items to:': 'Move items to:',
    'Move to Folder...': 'Move to Folder...',
    'Move to position...': 'Move to position...',
    'Moved to folder': 'Moved to folder',
    'My Store': 'My Store',
    'My Wantlist | Discogs': 'My Wantlist | Discogs',
    'Navigate to {title}': ['Navigate to ', ['title']],
    Navigation: 'Navigation',
    'Needs Changes': 'Needs Changes',
    'Needs Vote': 'Needs Vote',
    'Needs major changes': 'Needs major changes',
    'Needs minor changes': 'Needs minor changes',
    Never: 'Never',
    'New List': 'New List',
    'New Submission': 'New Submission',
    'Newest Addition': 'Newest Addition',
    Next: 'Next',
    'Next image': 'Next image',
    'Next<0/>': 'Next<0/>',
    'No image available': 'No image available',
    'No image available; add an image': 'No image available; add an image',
    'No items available in the Marketplace': 'No items available in the Marketplace',
    'No items found.': 'No items found.',
    'No items found. Try different keywords or check your spelling.':
        'No items found. Try different keywords or check your spelling.',
    'No versions could be found using these filters.': 'No versions could be found using these filters.',
    'Not acceptable.': 'Not acceptable.',
    'Not implemented.': 'Not implemented.',
    Notes: 'Notes',
    'Nothing in collection.': 'Nothing in collection.',
    Notifications: 'Notifications',
    'Notify me of new submissions related to this list.': 'Notify me of new submissions related to this list.',
    OK: 'OK',
    Okay: 'Okay',
    'Oldest Addition': 'Oldest Addition',
    'On The Go': 'On The Go',
    Optional: 'Optional',
    Options: 'Options',
    Orders: 'Orders',
    Other: 'Other',
    'Other Versions': 'Other Versions',
    'Other Versions ({0} of {total})': ['Other Versions (', ['0'], ' of ', ['total'], ')'],
    'Other Versions ({0})': ['Other Versions (', ['0'], ')'],
    'Overdubbed At': 'Overdubbed At',
    'Parent Label': 'Parent Label',
    Pause: 'Pause',
    'Payment required.': 'Payment required.',
    Permalink: 'Permalink',
    'Phonographic Copyright ℗': 'Phonographic Copyright ℗',
    Play: 'Play',
    'Play {title}': ['Play ', ['title']],
    Pop: 'Pop',
    'Pop Rock': 'Pop Rock',
    Portuguese: 'Portuguese',
    Position: 'Position',
    'Precondition failed.': 'Precondition failed.',
    'Precondition required.': 'Precondition required.',
    'Pressed By': 'Pressed By',
    'Pressing Plant ID': 'Pressing Plant ID',
    Prev: 'Prev',
    Preview: 'Preview',
    Previous: 'Previous',
    'Previous image': 'Previous image',
    'Price Code': 'Price Code',
    'Printed By': 'Printed By',
    Privacy: 'Privacy',
    'Privacy Policy': 'Privacy Policy',
    'Produced At': 'Produced At',
    'Produced For': 'Produced For',
    Profile: 'Profile',
    'Prog Rock': 'Prog Rock',
    'Proxy authentication required.': 'Proxy authentication required.',
    Public: 'Public',
    'Published By': 'Published By',
    Punk: 'Punk',
    Purchases: 'Purchases',
    'Quote Previous': 'Quote Previous',
    'Random Item': 'Random Item',
    Rating: 'Rating',
    'Rating (0-9)': 'Rating (0-9)',
    'Rating (9-0)': 'Rating (9-0)',
    Ratings: 'Ratings',
    'Read More': 'Read More',
    'Read More About Creating Lists': 'Read More About Creating Lists',
    'Real Name': 'Real Name',
    'Recent Lists': 'Recent Lists',
    'Recently Created Lists | Discogs Lists': 'Recently Created Lists | Discogs Lists',
    'Recently Edited': 'Recently Edited',
    'Recently Used': 'Recently Used',
    Recommendations: 'Recommendations',
    'Record Company': 'Record Company',
    'Record Stores': 'Record Stores',
    'Recorded At': 'Recorded At',
    'Recorded By': 'Recorded By',
    'Reel-To-Reel': 'Reel-To-Reel',
    Register: 'Register',
    'Related Videos': 'Related Videos',
    Release: 'Release',
    Released: 'Released',
    Releases: 'Releases',
    'Remastered At': 'Remastered At',
    'Remixed At': 'Remixed At',
    Remove: 'Remove',
    'Remove Item': 'Remove Item',
    'Remove Items': 'Remove Items',
    'Remove Rating': 'Remove Rating',
    'Remove Selected Release from Wantlist': 'Remove Selected Release from Wantlist',
    'Remove all versions from Wantlist': 'Remove all versions from Wantlist',
    'Remove from Collection': 'Remove from Collection',
    'Remove from Wantlist': 'Remove from Wantlist',
    Removed: 'Removed',
    'Removed Videos': 'Removed Videos',
    'Removed video': 'Removed video',
    Rename: 'Rename',
    Reply: 'Reply',
    Report: 'Report',
    'Report Suspicious Activity': 'Report Suspicious Activity',
    'Request entity too large.': 'Request entity too large.',
    'Request header fields too large.': 'Request header fields too large.',
    'Request timeout.': 'Request timeout.',
    'Request-URI too long.': 'Request-URI too long.',
    'Requested range not satisfiable.': 'Requested range not satisfiable.',
    'Revert to the old version.': 'Revert to the old version.',
    Reviews: 'Reviews',
    'Rights Society': 'Rights Society',
    Rock: 'Rock',
    Russian: 'Russian',
    'SPARS Code': 'SPARS Code',
    Save: 'Save',
    'Save Changes': 'Save Changes',
    'Save Fields': 'Save Fields',
    'Saved your changes': 'Saved your changes',
    'Saved your changes.': 'Saved your changes.',
    'Saving...': 'Saving...',
    'Scroll left': 'Scroll left',
    'Scroll right': 'Scroll right',
    Search: 'Search',
    'Search & Filters': 'Search & Filters',
    'Search Collection': 'Search Collection',
    'Search Discography': 'Search Discography',
    'Search Discogs': 'Search Discogs',
    'Search Lists': 'Search Lists',
    'Search artists, albums and more...': 'Search artists, albums and more...',
    'Search barcodes and other identifiers...': 'Search barcodes and other identifiers...',
    'Search suggestions': 'Search suggestions',
    'Search...': 'Search...',
    'See all versions': 'See all versions',
    'Select an item': 'Select an item',
    'Select {0} with {1} {2}': ['Select ', ['0'], ' with ', ['1'], ' ', ['2']],
    'Sell All Media': 'Sell All Media',
    'Sell Betacam-SP': 'Sell Betacam-SP',
    'Sell Blu-ray': 'Sell Blu-ray',
    'Sell Box Set': 'Sell Box Set',
    'Sell CD': 'Sell CD',
    'Sell CDr': 'Sell CDr',
    'Sell Cassette': 'Sell Cassette',
    'Sell DVD': 'Sell DVD',
    'Sell File': 'Sell File',
    'Sell Music': 'Sell Music',
    'Sell Reel-To-Reel': 'Sell Reel-To-Reel',
    'Sell U-Matic': 'Sell U-Matic',
    'Sell VHS': 'Sell VHS',
    'Sell Vinyl': 'Sell Vinyl',
    'Sell a Copy': 'Sell a Copy',
    'Sell a copy': 'Sell a copy',
    Seller: 'Seller',
    'Send a message': 'Send a message',
    Series: 'Series',
    'Service unavailable.': 'Service unavailable.',
    Settings: 'Settings',
    Share: 'Share',
    Shop: 'Shop',
    'Shop now': 'Shop now',
    'Shop this version': 'Shop this version',
    Show: 'Show',
    'Show Credits': 'Show Credits',
    'Show Shortcut Help': 'Show Shortcut Help',
    'Show all {0} tracks': ['Show all ', ['0'], ' tracks'],
    'Show all {totalCount} versions': ['Show all ', ['totalCount'], ' versions'],
    'Show images of {0}, {1}, {2}': ['Show images of ', ['0'], ', ', ['1'], ', ', ['2']],
    'Show less': 'Show less',
    'Show more': 'Show more',
    'Show more credits...': 'Show more credits...',
    'Showing {0}-{1} of {2}': ['Showing ', ['0'], '-', ['1'], ' of ', ['2']],
    'Showing {0}-{adjustedCount} of {adjustedTotalCount}': [
        'Showing ',
        ['0'],
        '-',
        ['adjustedCount'],
        ' of ',
        ['adjustedTotalCount'],
    ],
    Sites: 'Sites',
    'Sleeve condition': 'Sleeve condition',
    'Something went wrong': 'Something went wrong',
    'Something went wrong adding your review': 'Something went wrong adding your review',
    'Something went wrong in this section of the page.': 'Something went wrong in this section of the page.',
    'Something went wrong in this section.': 'Something went wrong in this section.',
    'Something went wrong.': 'Something went wrong.',
    'Sorry, this Artist does not have any Releases under that name.':
        'Sorry, this Artist does not have any Releases under that name.',
    'Sorry, this Label does not have any Releases under that name.':
        'Sorry, this Label does not have any Releases under that name.',
    Sort: 'Sort',
    Soul: 'Soul',
    Spanish: 'Spanish',
    'Start Selling': 'Start Selling',
    Statistics: 'Statistics',
    Status: 'Status',
    'Stop impersonating': 'Stop impersonating',
    'Stop impersonating {0}': ['Stop impersonating ', ['0']],
    Style: 'Style',
    Styles: 'Styles',
    Sublabels: 'Sublabels',
    'Submission Guidelines': 'Submission Guidelines',
    Submissions: 'Submissions',
    Submit: 'Submit',
    'Submit a Release': 'Submit a Release',
    'Switch to text-only view': 'Switch to text-only view',
    'Synth-Pop': 'Synth-Pop',
    'Terms of Service': 'Terms of Service',
    'Text area': 'Text area',
    'Text-only view': 'Text-only view',
    'That artist does not exist or may have been deleted.': 'That artist does not exist or may have been deleted.',
    'That label does not exist or may have been deleted.': 'That label does not exist or may have been deleted.',
    'That list does not exist or may have been deleted.': 'That list does not exist or may have been deleted.',
    'That release does not exist or may have been deleted.': 'That release does not exist or may have been deleted.',
    "The Artist {0} is invalid. Please refer to this Artist instead. View <0>{1}'s edit history</0>.": [
        'The Artist ',
        ['0'],
        ' is invalid. Please refer to this Artist instead. View <0>',
        ['1'],
        "'s edit history</0>.",
    ],
    "The Label {0} is invalid. Please refer to this Label instead. View <0>{1}'s edit history</0>.": [
        'The Label ',
        ['0'],
        ' is invalid. Please refer to this Label instead. View <0>',
        ['1'],
        "'s edit history</0>.",
    ],
    'The value "{id}" is not a valid Discogs identifier.': [
        'The value "',
        ['id'],
        '" is not a valid Discogs identifier.',
    ],
    'There are no videos here': 'There are no videos here',
    'This artist does not exist or may have been deleted.': 'This artist does not exist or may have been deleted.',
    'This artist is used as a placeholder entry and does not link to any artist.':
        'This artist is used as a placeholder entry and does not link to any artist.',
    'This master release does not exist or may have been deleted.':
        'This master release does not exist or may have been deleted.',
    'This release is blocked from sale on Discogs. It is not permitted to sell this item in our Marketplace.':
        'This release is blocked from sale on Discogs. It is not permitted to sell this item in our Marketplace.',
    'Thumbnail image': 'Thumbnail image',
    Title: 'Title',
    'Title (A-Z)': 'Title (A-Z)',
    'Title (Z-A)': 'Title (Z-A)',
    'Toggle Community Menu': 'Toggle Community Menu',
    'Toggle Explore Menu': 'Toggle Explore Menu',
    'Toggle Marketplace Menu': 'Toggle Marketplace Menu',
    'Toggle Zoom': 'Toggle Zoom',
    'Toggle section': 'Toggle section',
    'Too many requests.': 'Too many requests.',
    'Top pagination': 'Top pagination',
    Tracklist: 'Tracklist',
    'Tracks {0} — {1} of {2}': ['Tracks ', ['0'], ' — ', ['1'], ' of ', ['2']],
    Twitter: 'Twitter',
    Type: 'Type',
    'U-Matic': 'U-Matic',
    'Unauthorized.': 'Unauthorized.',
    'Unavailable due to legal reasons.': 'Unavailable due to legal reasons.',
    'Undo changes': 'Undo changes',
    Unknown: 'Unknown',
    'Unprocessable entity.': 'Unprocessable entity.',
    'Unsupported media type.': 'Unsupported media type.',
    'Use drag and drop to re-order the items in your list, or click "move to," type a new position, and press enter.':
        'Use drag and drop to re-order the items in your list, or click "move to," type a new position, and press enter.',
    User: 'User',
    'User Menu': 'User Menu',
    VHS: 'VHS',
    Variations: 'Variations',
    'Version Details': 'Version Details',
    Versions: 'Versions',
    'Video Player': 'Video Player',
    'Video Search': 'Video Search',
    Videos: 'Videos',
    'Videos from related releases, artists, or labels.': 'Videos from related releases, artists, or labels.',
    'View All': 'View All',
    'View Help': 'View Help',
    'View Images for Selected Release': 'View Images for Selected Release',
    'View More {name}': ['View More ', ['name']],
    'View Submissions': 'View Submissions',
    'View all of my lists': 'View all of my lists',
    'View credits, reviews, tracks and shop for the {info} release of "{title}" on Discogs.': [
        'View credits, reviews, tracks and shop for the ',
        ['info'],
        ' release of "',
        ['title'],
        '" on Discogs.',
    ],
    'View in admin': 'View in admin',
    Viewing: 'Viewing',
    'Viewing All': 'Viewing All',
    Vinyl: 'Vinyl',
    'Visit Wantlister': 'Visit Wantlister',
    Want: 'Want',
    'Want to see other lists from the Discogs Community? Check out <0>Recent Lists.</0>':
        'Want to see other lists from the Discogs Community? Check out <0>Recent Lists.</0>',
    'Want to see other lists from the Discogs Community? Check out <0>Recent Lists</0>.':
        'Want to see other lists from the Discogs Community? Check out <0>Recent Lists</0>.',
    Wanted: 'Wanted',
    Wantlist: 'Wantlist',
    'We could not find that page.': 'We could not find that page.',
    Year: 'Year',
    'Year (0-9)': 'Year (0-9)',
    'Year (9-0)': 'Year (9-0)',
    'You are currently viewing the new version of the release page.':
        'You are currently viewing the new version of the release page.',
    'You are impersonating {0}.': ['You are impersonating ', ['0'], '.'],
    'You are viewing the new version of the Master Page.': 'You are viewing the new version of the Master Page.',
    'You can update your default language preference in your <0>General Settings</0>.':
        'You can update your default language preference in your <0>General Settings</0>.',
    'You clicked on the {from} version of this page.': ['You clicked on the ', ['from'], ' version of this page.'],
    'You do not have any custom note fields for your collection. Create some by clicking Add a field':
        'You do not have any custom note fields for your collection. Create some by clicking Add a field',
    'You need to be logged in to see this page.': 'You need to be logged in to see this page.',
    'YouTube search query:': 'YouTube search query:',
    'Zoom In': 'Zoom In',
    'Zoom Out': 'Zoom Out',
    from: 'from',
    'no rating': 'no rating',
    'not rated': 'not rated',
    referencing: 'referencing',
    remove: 'remove',
    'tracks: {0}': ['tracks: ', ['0']],
    'updated just now': 'updated just now',
    '{0, plural, one {# copy} other {# copies}}': [['0', 'plural', { one: ['#', ' copy'], other: ['#', ' copies'] }]],
    '{0, plural, one {+# more label...} other {+# more labels...}}': [
        ['0', 'plural', { one: ['+', '#', ' more label...'], other: ['+', '#', ' more labels...'] }],
    ],
    '{0, plural, one {See # reply} other {See # replies}}': [
        ['0', 'plural', { one: ['See ', '#', ' reply'], other: ['See ', '#', ' replies'] }],
    ],
    '{0, plural, one {rated this release 1 star} other {rated this release # stars}}': [
        ['0', 'plural', { one: 'rated this release 1 star', other: ['rated this release ', '#', ' stars'] }],
    ],
    '{0}': [['0']],
    '{0} from {1}': [['0'], ' from ', ['1']],
    '{0} in Inventory': [['0'], ' in Inventory'],
    "{0}'s avatar": [['0'], "'s avatar"],
    '{artistName} - {title} album cover': [['artistName'], ' - ', ['title'], ' album cover'],
    '{copiesText} for sale': [['copiesText'], ' for sale'],
    '{count, plural, one {# unread message} other {# unread messages}}': [
        ['count', 'plural', { one: ['#', ' unread message'], other: ['#', ' unread messages'] }],
    ],
    '{count} in Collection': [['count'], ' in Collection'],
    '{count} in Inventory': [['count'], ' in Inventory'],
    '{count} in Wantlist': [['count'], ' in Wantlist'],
    '{count} in cart': [['count'], ' in cart'],
    '{count} results are available, use up and down arrow keys to navigate.': [
        ['count'],
        ' results are available, use up and down arrow keys to navigate.',
    ],
    '{days, plural, one {Added # day ago} other {Added # days ago}}': [
        ['days', 'plural', { one: ['Added ', '#', ' day ago'], other: ['Added ', '#', ' days ago'] }],
    ],
    '{days, plural, one {Edited one day ago} other {Edited # days ago}}': [
        ['days', 'plural', { one: 'Edited one day ago', other: ['Edited ', '#', ' days ago'] }],
    ],
    '{days, plural, one {updated # day ago} other {updated # days ago}}': [
        ['days', 'plural', { one: ['updated ', '#', ' day ago'], other: ['updated ', '#', ' days ago'] }],
    ],
    '{heading}': [['heading']],
    '{hours, plural, one {Added # hour ago} other {Added # hours ago}}': [
        ['hours', 'plural', { one: ['Added ', '#', ' hour ago'], other: ['Added ', '#', ' hours ago'] }],
    ],
    '{hours, plural, one {Edited one hour ago} other {Edited # hours ago}}': [
        ['hours', 'plural', { one: 'Edited one hour ago', other: ['Edited ', '#', ' hours ago'] }],
    ],
    '{hours, plural, one {updated # hour ago} other {updated # hours ago}}': [
        ['hours', 'plural', { one: ['updated ', '#', ' hour ago'], other: ['updated ', '#', ' hours ago'] }],
    ],
    '{key}': [['key']],
    '{min, plural, one {Rate this release 1 star} other {Rate this release # stars}}': [
        ['min', 'plural', { one: 'Rate this release 1 star', other: ['Rate this release ', '#', ' stars'] }],
    ],
    '{minutes, plural, one {Added # minute ago} other {Added # minutes ago}}': [
        ['minutes', 'plural', { one: ['Added ', '#', ' minute ago'], other: ['Added ', '#', ' minutes ago'] }],
    ],
    '{minutes, plural, one {Edited one minute ago} other {Edited # minutes ago}}': [
        ['minutes', 'plural', { one: 'Edited one minute ago', other: ['Edited ', '#', ' minutes ago'] }],
    ],
    '{minutes, plural, one {updated # minute ago} other {updated # minutes ago}}': [
        ['minutes', 'plural', { one: ['updated ', '#', ' minute ago'], other: ['updated ', '#', ' minutes ago'] }],
    ],
    '{months, plural, one {Added # month ago} other {Added # months ago}}': [
        ['months', 'plural', { one: ['Added ', '#', ' month ago'], other: ['Added ', '#', ' months ago'] }],
    ],
    '{months, plural, one {Edited one month ago} other {Edited # months ago}}': [
        ['months', 'plural', { one: 'Edited one month ago', other: ['Edited ', '#', ' months ago'] }],
    ],
    '{months, plural, one {updated # month ago} other {updated # months ago}}': [
        ['months', 'plural', { one: ['updated ', '#', ' month ago'], other: ['updated ', '#', ' months ago'] }],
    ],
    '{name}': [['name']],
    '{name} Discography | Discogs': [['name'], ' Discography | Discogs'],
    '{name} by {username} | Discogs Lists': [['name'], ' by ', ['username'], ' | Discogs Lists'],
    '{name} image': [['name'], ' image'],
    '{name} | Discogs': [['name'], ' | Discogs'],
    '{name}, Primary, {0} of {1}': [['name'], ', Primary, ', ['0'], ' of ', ['1']],
    '{name}, Secondary, {0} of {1}': [['name'], ', Secondary, ', ['0'], ' of ', ['1']],
    '{num} of {total} results': [['num'], ' of ', ['total'], ' results'],
    '{pendingSubmissionsCount} submissions pending': [['pendingSubmissionsCount'], ' submissions pending'],
    '{referenceType}s': [['referenceType'], 's'],
    '{title}': [['title']],
    '{totalFiltersApplied} Applied': [['totalFiltersApplied'], ' Applied'],
    '{total} versions': [['total'], ' versions'],
    "{username}'s avatar": [['username'], "'s avatar"],
    '{value}': [['value']],
    '{versionCount, plural, one {# credit} other {# credits}}': [
        ['versionCount', 'plural', { one: ['#', ' credit'], other: ['#', ' credits'] }],
    ],
    '{versionCount, plural, one {# version} other {# versions}}': [
        ['versionCount', 'plural', { one: ['#', ' version'], other: ['#', ' versions'] }],
    ],
    '{years, plural, one {Added # year ago} other {Added # years ago}}': [
        ['years', 'plural', { one: ['Added ', '#', ' year ago'], other: ['Added ', '#', ' years ago'] }],
    ],
    '{years, plural, one {Edited one year ago} other {Edited # years ago}}': [
        ['years', 'plural', { one: 'Edited one year ago', other: ['Edited ', '#', ' years ago'] }],
    ],
    '{years, plural, one {updated # year ago} other {updated # years ago}}': [
        ['years', 'plural', { one: ['updated ', '#', ' year ago'], other: ['updated ', '#', ' years ago'] }],
    ],
}
