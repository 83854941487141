import * as React from 'react'

import { Link } from '../../lib/components/link'
import { Content } from '../../lib/components/content'

import releases from '../../../releases.json'
import css from './styles.css'

type ReleaseInfo = {
    discogsId: number
    title: string
    artists: string
    siteUrl: string
}

export default function HomePage(): React.ReactElement {
    const id = 1 + Math.floor(Math.random() * 15000000)
    const random = `/random/release/${id}`
    const randomArtist = `/artist/${id}`

    const links = Object.values(releases).map(
        (info: ReleaseInfo): React.ReactElement => (
            <li key={info.discogsId}>
                <Link href={info.siteUrl}>
                    {info.artists} &mdash; {info.title}
                </Link>
            </li>
        ),
    )

    return (
        <Content className={css.home}>
            <h2>Using Local Testing Data</h2>
            <h2>
                Users - you can add things to the following users collections/wantlist <br /> etc but remember this data
                will be reset when you rebuild the local testing data container in the monolith
            </h2>
            <h3>important</h3>
            <h3>
                If you are running the local env on a page where the user is logged in (you will see ?impersonate=1) in
                the url
                <br />
                replace the 1 with your own discogs user ID otherwise you will be making changes on a real users account
            </h3>
            <ul>
                <li>
                    <Link href='/user/test/collection?impersonate=1'>
                        test user 1 (takes you to their collection page)
                    </Link>
                </li>
                <li>
                    <Link href='/user/test2/collection?impersonate=2'>
                        test user 2 (takes you to their collection page)
                    </Link>
                </li>
            </ul>
            <h3>Label Page</h3>
            <ul>
                <li>
                    <Link href='/label/245-R-S-Records'>R & S Records - this label has the most data</Link>
                </li>
                <li>
                    <Link href='/label/23528-Warp-Records'>Warp</Link>
                </li>
            </ul>
            <h3>Artist Page</h3>
            <ul>
                <li>
                    <Link href='/artist/45467'>Pink Floyd</Link>
                </li>
                <li>
                    <Link href='/artist/45'>Aphex Twin</Link>
                </li>
                <li>
                    <Link href='/artist/41'>Autechre - this artist has the most data</Link>
                </li>
                <li>
                    <Link href='/artist/39'>Baby Mammoth</Link>
                </li>
                <li>
                    <Link href='/artist/414'>Funkstörung</Link>
                </li>
            </ul>
            <h3>Releases</h3>
            <ul>
                <li>
                    <Link href='/release/3155123'>Aphex Twin - Selected Ambient Works 85-92</Link>
                </li>
                <li>
                    <Link href='/release/1717369'>Autechre - Amber</Link>
                </li>
                <li>
                    <Link href='/release/35547'>Jaydee - Plastic Dreams</Link>
                </li>
                <li>
                    <Link href='/release/3313'>St. Etienne* - Casino Classics</Link>
                </li>
                <li>
                    <Link href='/release/156669'>
                        Autechre, Boards Of Canada, The Black Dog – Warp Peel Sessions Vol. 1
                    </Link>
                </li>
                <li>
                    <Link href='/release/1748191-DJ-Kamikaz-Abstractions-Of-Sound'>
                        DJ Kamikaz - Abstractions Of Sound
                    </Link>
                </li>
                <li>
                    <Link href='/release/3443'>East Flatbush Project - Tried By 12 Remixes</Link>
                </li>
                <li>
                    <Link href='/release/2774332'>Plus Ultra - Autonomic Podcast Layer 02</Link>
                </li>
            </ul>
            <h3>Masters</h3>
            <ul>
                <li>
                    <Link href='/master/565'>Aphex Twin - Selected Ambient Works 85-92</Link>
                </li>
                <li>
                    <Link href='/master/67542'>St. Etienne - Casino Classics</Link>
                </li>
            </ul>
            <h2>Using Production Data - not recommended</h2>
            <h3>Artist Page</h3>
            <ul>
                <li>
                    <Link href='/artist/45467-Pink-Floyd'>Pink Floyd</Link>
                </li>
                <li>
                    <Link href='/artist/68140-Bohren-Der-Club-Of-Gore'>Bohren und der club of gore</Link>
                </li>
                <li>
                    <Link href='/artist/4507769-Metatron-Omega'>Metatron Omega</Link>
                </li>
                <li>
                    <Link href='/artist/411447-Nils-Frahm'>Nils Frahm</Link>
                </li>
                <li>
                    <Link href='/artist/171698-Max-Richter'>Max Richter</Link>
                </li>
                <li>
                    <Link href='/artist/312974'>First Artist with Alt-Artist value</Link>
                </li>
                <li>
                    <Link href='/artist/9305896'> Second Artist with Alt-Artist value</Link>
                </li>
                <li>
                    <Link href={randomArtist}>Show a random Artist</Link>
                </li>
            </ul>
            <h3>Releases</h3>
            <ul>{links}</ul>
            <Link href={random}>Show a random release</Link>

            <h3>Masters</h3>
            <ul>
                <li>
                    <Link href='/master/138587'>John Lennon And Yoko Ono* – Unfinished Music No. 1. Two Virgins</Link>
                </li>
            </ul>
        </Content>
    )
}
