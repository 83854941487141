import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'

import { defaultLanguage } from './lib/i18n'

import { RemoveLocale } from './lib/components/remove-locale'
import { RedirectLegacyImages } from './lib/components/redirect-image'
import { Layout } from './lib/components/layout'
import { Page } from './lib/components/page'
import { useLogin } from './lib/login'
import { addLocale } from './lib/add-locale'

import NotFound from './pages/not-found'

import { paths as homePagePaths } from './pages/home/paths'
import { paths as releasePagePaths } from './pages/release-page/paths'
import { paths as artistPagePaths } from './pages/artist/paths'
import { paths as releaseEditVideosPaths } from './pages/release-edit-videos/paths'
import { paths as masterPagePaths } from './pages/master/paths'
import { paths as artistEditVideosPaths } from './pages/artist-videos-edit/paths'
import { paths as labelPagePaths } from './pages/label/paths'
import { paths as collectionPagePaths } from './pages/collection/paths'
import { paths as collectionFoldersSettingsPaths } from './pages/collection-folders-settings/paths'
import { paths as collectionFieldsSettingsPaths } from './pages/collection-fields-settings/paths'
import { paths as curatedListPagePaths } from './pages/curated-list/paths'
import { paths as listExplorerPaths } from './pages/list-explorer/paths'
import { paths as listsByUserPaths } from './pages/lists-by-user/paths'
import { paths as wantlistPaths } from './pages/wantlist/paths'

const HomePage = loadable(() => import('./pages/home'))
const ReleasePage = loadable(() => import('./pages/release-page'))
const ArtistPage = loadable(() => import('./pages/artist'))
const ReleaseEditVideos = loadable(() => import('./pages/release-edit-videos'))
const MasterPage = loadable(() => import('./pages/master'))
const ArtistEditVideos = loadable(() => import('./pages/artist-videos-edit'))
const HealthCheck = loadable(() => import('./pages/health-check'))
const LabelPage = loadable(() => import('./pages/label'))
const CollectionPage = loadable(() => import('./pages/collection'))
const CollectionFoldersSettings = loadable(() => import('./pages/collection-folders-settings'))
const CollectionFieldsSettings = loadable(() => import('./pages/collection-fields-settings'))
const CuratedListPage = loadable(() => import('./pages/curated-list'))
const ListExplorerPage = loadable(() => import('./pages/list-explorer'))
const ListsByUserPage = loadable(() => import('./pages/lists-by-user'))
const WantlistPage = loadable(() => import('./pages/wantlist'))

export function App(): React.ReactElement {
    const login = useLogin()

    return (
        <Layout>
            <RemoveLocale locale={defaultLanguage} />
            <RedirectLegacyImages />
            <Page {...login}>
                <Switch>
                    <Route exact path={addLocale(homePagePaths)} component={HomePage} />
                    <Route exact path='/healthcheck' component={HealthCheck} />
                    <Route exact path={addLocale(releaseEditVideosPaths)} component={ReleaseEditVideos} />
                    <Route exact path={addLocale(artistEditVideosPaths)} component={ArtistEditVideos} />
                    <Route path={addLocale(releasePagePaths)} component={ReleasePage} />
                    <Route path={addLocale(masterPagePaths)} component={MasterPage} />
                    <Route path={addLocale(artistPagePaths)} component={ArtistPage} />
                    <Route path={addLocale(labelPagePaths)} component={LabelPage} />
                    <Route path={addLocale(collectionPagePaths)} component={CollectionPage} />
                    <Route path={addLocale(collectionFoldersSettingsPaths)} component={CollectionFoldersSettings} />
                    <Route path={addLocale(collectionFieldsSettingsPaths)} component={CollectionFieldsSettings} />
                    <Route path={addLocale(curatedListPagePaths)} component={CuratedListPage} />
                    <Route path={addLocale(listExplorerPaths)} component={ListExplorerPage} />
                    <Route path={addLocale(listsByUserPaths)} component={ListsByUserPage} />
                    <Route path={addLocale(wantlistPaths)} component={WantlistPage} />

                    {/* Fall back to 404 page */}
                    <Route component={NotFound} />
                </Switch>
            </Page>
        </Layout>
    )
}
